import { I18nModule } from '@agdir/i18n/angular';
import { BadgeComponent } from '@agdir/ui/badge';
import { AgdirColors } from '@agdir/ui/colors';
import { SpinnerComponent } from '@agdir/ui/loaders';
import { DatePipe, NgForOf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { firstValueFrom } from 'rxjs';
import { InvitationService } from './invitation.service';
import { Invitation } from '../domain/invitation';
import { InvitationStatus } from '../domain/invitation.status';
import { provideTranslocoScope } from '@ngneat/transloco';
import { ButtonComponent } from '@agdir/ui/button';
import { AgdirCardComponent } from '@agdir/core/angular';

@Component({
	standalone: true,
	selector: 'agdir-invitations-list-component',
	template: `
		<agdir-card title="auth.signUpPage.invitationsView.invites">
			<div class="grid grid-cols-2 lg:grid-cols-4 gap-2">
				@if (showPhone || showInvitedBy) {
					<div class="opacity-50 max-lg:hidden" transloco="auth.signUpPage.invitationsView.invitedBy"></div>
				}
				<div class="opacity-50  max-lg:hidden" transloco="auth.signUpPage.invitationsView.invitedTo"></div>
				<div class="opacity-50  max-lg:hidden" transloco="auth.signUpPage.invitationsView.invitedAt"></div>
				<div class="opacity-50  max-lg:hidden" transloco="auth.signUpPage.invitationsView.status"></div>
				@for (i of invitations; track '_id') {
					<div class="grid-container col-span-2 lg:col-span-4 hover:bg-gray-100">
						<div class="grid-item-1 ">
							<span class="opacity-50 lg:hidden" transloco="auth.signUpPage.invitationsView.invitedBy"></span>
							@if (showPhone) {
								<span>
									{{ i.invitee?.name }}
									{{ i.invitee?.surname }}
									({{ i.invitee?.phoneNumber }})
								</span>
							}
							@if (showInvitedBy) {
								<span>{{ i.inviter?.name }}</span>
							}
						</div>
						<div class="grid-item-2">
							<span class="opacity-50 lg:hidden" transloco="auth.signUpPage.invitationsView.invitedTo"></span>
							{{ i.company?.organizationName }}
						</div>
						<div class="grid-item-3">
							@if (i.createdAt) {
								<span class="opacity-50 lg:hidden" transloco="auth.signUpPage.invitationsView.invitedAt"></span>
								<time [innerText]="i.createdAt | date: 'shortDate'"></time>
							}
							<!--						@if (i.changedAt) {-->
							<!--							<span transloco="invitations.status.{{ i.status }}At"></span>-->
							<!--							<time [innerText]="i.changedAt | date: 'shortDate'" class="ml-2"></time>-->
							<!--						}-->
						</div>
						<div class="grid-item-4">
							@if (showStatus) {
								<agdir-badge
									[color]="
										i.status === InvitationStatus.PENDING ? 'blue' : i.status === InvitationStatus.ACCEPTED ? 'green' : 'red'
									"
									class="min-w-20 @lg:mr-5"
									label="{{ 'invitations.status.' + i.status | transloco }}"
								/>
							}
							@if (processingInvites.has(i)) {
								<agdir-spinner />
							}
							@if (showDecline && i.status === 'pending') {
								<agdir-button
									(click)="changeStatus(i, InvitationStatus.DECLINED)"
									[attr.data-cy]="'declineInvite' + i?.company?._id"
									color="red"
									icon="close"
								/>
							}
							@if (showRevoke && i.status === 'pending') {
								<agdir-button
									(click)="changeStatus(i, InvitationStatus.REVOKED)"
									[attr.data-cy]="'revokeInvite' + i?.company?._id"
									color="red"
									icon="close"
								/>
							}
							<div class="flex-col-start lg:flex-row-start gap-1">
								@if (showDelete) {
									<agdir-button
										(click)="delete(i)"
										[attr.data-cy]="'deleteInvite' + i?.company?._id"
										color="ghost"
										icon="delete"
										class="text-red-500"
										label="auth.signUpPage.invitationsView.decline"
									/>
								}
								@if (showAccept && i.status === 'pending') {
									<agdir-button
										(click)="changeStatus(i, InvitationStatus.ACCEPTED)"
										[attr.data-cy]="'acceptInvite' + i?.company?._id"
										color="green"
										icon="check"
										label="auth.signUpPage.invitationsView.accept"
									/>
								}
							</div>
						</div>
					</div>
				} @empty {
					@if (showNoInvitationsText) {
						<div class="text-center text-gray" transloco="auth.signUpPage.invitationsView.noInvites"></div>
					}
				}
			</div>
		</agdir-card>
	`,
	styles: [
		`
			.grid-container {
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-template-rows: auto auto auto;
			}

			.grid-item-1 {
				grid-column: 1 / 2;
				grid-row: 1 / 2;
				@apply flex-row-start gap-1;
			}

			.grid-item-2 {
				grid-column: 1 / 2;
				grid-row: 2 / 3;
				@apply flex-row-start gap-1;
			}

			.grid-item-3 {
				grid-column: 1 / 2;
				grid-row: 3 / 4;
				@apply flex-row-start gap-1;
			}

			.grid-item-4 {
				grid-column: 2 / 3;
				grid-row: 1 / 4;
				@apply flex-row-start gap-1;
			}

			@media (min-width: 1280px) {
				.grid-container {
					grid-template-columns: repeat(4, 1fr);
					grid-template-rows: min-content;
				}

				.grid-item-1,
				.grid-item-2,
				.grid-item-3,
				.grid-item-4 {
					grid-row: auto;
					grid-column: auto;
				}
			}
		`,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [NgForOf, BadgeComponent, I18nModule, DatePipe, SpinnerComponent, MatButtonModule, ButtonComponent, AgdirCardComponent],
	providers: [provideTranslocoScope({ scope: 'invitations', alias: 'invitations' })],
})
export class InvitationsListComponent {
	@Input() invitations: Invitation[] = [];
	@Input() showStatus = true;
	@Input() showDecline = false;
	@Input() showAccept = true;
	@Input() showRevoke = true;
	@Input() showDelete = false;
	@Input() showPhone = true;
	@Input() showInvitedBy = false;
	@Input() showCompany = false;
	@Input() showNoInvitationsText = false;
	@Output() statusChanged = new EventEmitter<Invitation>();
	processingInvites = new Set<Invitation>();
	AgdirColors = AgdirColors;
	InvitationStatus = InvitationStatus;

	constructor(private invitationService: InvitationService) {}

	async delete(i: Invitation) {
		this.processingInvites.add(i);
		await firstValueFrom(this.invitationService.delete(i));
		i.status = InvitationStatus.DELETED;
		this.processingInvites.delete(i);
		i.changedAt = new Date();
		this.statusChanged.next(i);
	}

	async changeStatus(i: Invitation, newStatus: InvitationStatus) {
		this.processingInvites.add(i);
		switch (newStatus) {
			case InvitationStatus.ACCEPTED:
				await firstValueFrom(this.invitationService.accept(i));
				break;
			case InvitationStatus.DECLINED:
				await firstValueFrom(this.invitationService.decline(i));
				break;
			case InvitationStatus.DELETED:
				await firstValueFrom(this.invitationService.delete(i));
				break;
		}
		i.status = newStatus;
		this.processingInvites.delete(i);
		i.changedAt = new Date();
		this.statusChanged.next(i);
	}
}
